import { SET_THERMAL_SCAN_DATA, SET_CURRENT_SNAPSHOT_DATE } from '../actionTypes';

export const setThermalScanData = ({ thermalScanData }) => ({
    type: SET_THERMAL_SCAN_DATA,
    data: thermalScanData,
});

export const setCurrentSnapshotDate = ({ date }) => ({
    type: SET_CURRENT_SNAPSHOT_DATE,
    data: date,
});
