import React from 'react';
import PropTypes from 'prop-types';
import LeftPanel from '../LeftPanel';
import RightPanelMap from '../RightPanelMap';
import Map from '../Map';
import RightPanel from '../RightPanel';
import BhomeView from '../BhomeView';
import useResize from '../../hooks/useResize';

const BhomeViewWrapper = ({ isPublicView = false, hideMap = false, code }) => {
    const isSmallScreen = useResize();

    const mapComponent = isSmallScreen ? <RightPanelMap /> : <Map />;

    return (
        <main className="main main-bhome">
            {!isPublicView && <LeftPanel />}
            <BhomeView isPublicView={isPublicView} />
            {!hideMap && mapComponent}
            <RightPanel isPublicView={isPublicView} code={code} />
        </main>
    );
};

BhomeViewWrapper.propTypes = {
    isPublicView: PropTypes.bool,
    hideMap: PropTypes.bool,
    code: PropTypes.string,
};

export default BhomeViewWrapper;
