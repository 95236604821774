import dayjs from 'dayjs';
// move here reducer from beehome
import { FETCH_THERMAL_SCAN_DATA, SET_THERMAL_SCAN_DATA, SET_CURRENT_SNAPSHOT_DATE } from '../actionTypes';

const initialState = {
    thermalScanData: [],
    currentSnapshotDate: dayjs().format('YYYY-MM-DD'),
};

const bhome = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_THERMAL_SCAN_DATA.success:
        case SET_THERMAL_SCAN_DATA:
            return {
                ...state,
                thermalScanData: action.data,
            };
        case SET_CURRENT_SNAPSHOT_DATE:
            return {
                ...state,
                currentSnapshotDate: action.data,
            };
        default:
            return state;
    }
};

export default bhome;
