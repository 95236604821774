import dayjs from 'dayjs';

const getTooltipWithLatestScanForAllFrames = ({ frames, isBeeCountMode, showFramesHistory }) => {
    if (!frames.length) {
        return null;
    }

    let hiveLatestScan = 0;

    frames.forEach(frame => {
        if (!frame?.frameId) {
            return;
        }
        const scanTimestampA =
            (isBeeCountMode ? frame.sideA?.beeCount?.scanTimestamp : frame.sideA?.scanTimestamp) ?? 0;
        const scanTimestampB =
            (isBeeCountMode ? frame.sideB?.beeCount?.scanTimestamp : frame.sideB?.scanTimestamp) ?? 0;
        const previousScanTimestampA =
            (isBeeCountMode ? frame.previousSideA?.beeCount?.scanTimestamp : frame.previousSideA?.scanTimestamp) ?? 0;
        const previousScanTimestampB =
            (isBeeCountMode ? frame.previousSideB?.beeCount?.scanTimestamp : frame.previousSideB?.scanTimestamp) ?? 0;
        const latestScan = showFramesHistory
            ? Math.max(previousScanTimestampA, previousScanTimestampB)
            : Math.max(scanTimestampA, scanTimestampB);
        if (latestScan > hiveLatestScan) {
            hiveLatestScan = latestScan;
        }
    });
    if (!hiveLatestScan) {
        return null;
    }

    return `Over 14 days since last scan (${dayjs(hiveLatestScan).format('MMM D')})`;
};

export default getTooltipWithLatestScanForAllFrames;
