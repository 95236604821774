import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useImageWithFallback } from 'utils/useImageWithFallback';
import ContentLegend from 'components/reusables/HivesV2/Legend';
import { NotAvailableScan } from 'components/reusables/RightPanel/components/HiveBlock/components/components';
import { setFrameToInspect } from 'components/views/BeeHome/actions';
import { getCombFrameImages } from 'components/reusables/RightPanel/components/HiveBlock/utils';
import { getBhomeDefinedHives } from 'components/views/BeeHome/selectors';
import useTransformedImagesData from 'components/reusables/RightPanel/components/HiveBlock/useTransformedImagesData';
import ActionIcon from 'components/reusables/ActionIcon';
import IconItem from './components/IconItem';
import Magnifier from '../Magnifier';
import { DIRECTION, getCurrentHiveFrames, SIDE, IMAGE_TYPES } from '../../utils';
import './FullScreenImageModal.scss';

const standardMagnifierSize = 128;

const SIDES = [SIDE.A, SIDE.B];

const FullScreenImageModal = ({
    onClose,
    image,
    title,
    handleToggleShowAnnotatedPhotos,
    isAnnotatedPhotosShown,
    setFullScreenImage,
    inspectedData,
    inspectedFrame,
}) => {
    const dispatch = useDispatch();
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);

    const { beeCountImages, frameImages: scanImages } = useTransformedImagesData(inspectedData?.latestData?.images);

    const { currentImage, isLoading, error } = useImageWithFallback({
        primaryImageUrl: image?.url,
        fallbackImageUrl: image?.resizedUrl,
    });

    const currentHiveCombFrames = getCurrentHiveFrames(hives, inspectedFrame);

    const inspectedFrameIndex = currentHiveCombFrames?.findIndex(frame => frame.frameId === inspectedFrame?.frameId);
    const totalFrames = currentHiveCombFrames?.length ?? 0;
    const bottomTitle = inspectedFrameIndex + 1 && totalFrames ? `${inspectedFrameIndex + 1}/${totalFrames}` : '';

    const combinedImages = useMemo(
        () =>
            [
                ...SIDES.flatMap(side => ({
                    side,
                    type: IMAGE_TYPES.SCAN,
                    ...getCombFrameImages(inspectedFrame, scanImages, side, isAnnotatedPhotosShown),
                })),
                ...SIDES.flatMap(side => ({
                    side,
                    type: IMAGE_TYPES.BEE_COUNT,
                    ...getCombFrameImages(inspectedFrame, beeCountImages, side, isAnnotatedPhotosShown),
                })),
            ].filter(image => image.url || image.resizedUrl),
        [inspectedFrame, scanImages, beeCountImages, isAnnotatedPhotosShown]
    );

    const handleImageChange = index => () =>
        setFullScreenImage(prevState => {
            const newIndex = (prevState.index + index + combinedImages.length) % combinedImages.length;

            if (!combinedImages[newIndex]) {
                return prevState;
            }

            const { side, type, ...newImage } = combinedImages[newIndex];

            return {
                ...prevState,
                image: newImage,
                index: newIndex,
                side,
                type,
                rootImages: type === IMAGE_TYPES.SCAN ? scanImages : beeCountImages,
            };
        });

    const handleFrameChange = direction => () => {
        setFullScreenImage(prevState => ({
            ...prevState,
            image: null,
        }));

        dispatch(setFrameToInspect({}));

        const newIndex = (inspectedFrameIndex + direction + totalFrames) % totalFrames;

        dispatch(setFrameToInspect(currentHiveCombFrames[newIndex]));
    };

    if (isLoading) {
        return (
            <Modal isOpen className="full-screen-image-modal-updated">
                <div className="full-screen-image-modal">
                    <p>Loading...</p>
                </div>
            </Modal>
        );
    }

    return (
        <Modal isOpen className="full-screen-image-modal-updated" onClose={onClose}>
            <div className="full-screen-image-modal">
                <div className="full-screen-image-modal-top">
                    <div className="full-screen-image-modal-top-left">
                        <FontAwesomeIcon
                            icon={faXmark}
                            onClick={onClose}
                            className="full-screen-image-modal-top-left-icon"
                        />
                        <div className="full-screen-image-modal-top-left-title">{title}</div>
                    </div>
                    <ActionIcon
                        onClick={handleToggleShowAnnotatedPhotos}
                        name="Ai View"
                        isActive={isAnnotatedPhotosShown}
                        icon="ai"
                    />
                </div>
                <div className="full-screen-image-modal-content">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={handleImageChange(-1)}
                        className="modal-direction-icon full-screen-image-modal-content-left"
                    />
                    {(!image?.url && !image?.resizedUrl) || error ? (
                        <NotAvailableScan />
                    ) : (
                        <Magnifier
                            src={currentImage}
                            mgShape="square"
                            mgWidth={standardMagnifierSize}
                            mgHeight={standardMagnifierSize}
                            zoomFactor={2}
                        />
                    )}
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        onClick={handleImageChange(1)}
                        className="modal-direction-icon full-screen-image-modal-content-right"
                    />
                </div>
                <ContentLegend />
                <div className="full-screen-image-modal-bottom">
                    <IconItem
                        icon={faChevronLeft}
                        handleFrameChange={handleFrameChange(-1)}
                        hidden={!inspectedFrameIndex}
                        side={DIRECTION.LEFT}
                    />
                    <div>{bottomTitle}</div>
                    <IconItem
                        icon={faChevronRight}
                        handleFrameChange={handleFrameChange(1)}
                        hidden={inspectedFrameIndex + 1 === totalFrames}
                        side={DIRECTION.RIGHT}
                    />
                </div>
            </div>
        </Modal>
    );
};

FullScreenImageModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    image: PropTypes.shape({ url: PropTypes.string, resizedUrl: PropTypes.string }),
    title: PropTypes.element,
    isAnnotatedPhotosShown: PropTypes.bool,
    handleToggleShowAnnotatedPhotos: PropTypes.func,
    setFullScreenImage: PropTypes.func,
    inspectedData: PropTypes.shape(),
    inspectedFrame: PropTypes.shape(),
};

export default FullScreenImageModal;
