import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { showToast } from '@beewise/toast';
import { getIsLoggedInAsSuperAdmin, getRoles, isLoggedAsAdmin } from 'components/views/Admin/selectors';
import { useCompareEffect } from '@beewise/react-utils/build/lib/previous';
import { getIsLimitedViewCompany } from 'selectors';
import {
    closeInvitationModel,
    getCompany,
    openInvitationModel,
    updateRole,
    fetchUserSettings,
    fetchUpdateUserAlertsSettings,
    fetchUserPreferences,
} from './actions';
import { getCompanyUsersWithPermissions, getIsInvitationModelOpen, getUserSettings } from './selectors';
import InvitationModal from './components/InvitationModal';
import { calculateUserRole } from './utils';
import Menu from './components/Menu';
import Team from './components/Team';
import BeehomesSettings from './components/BeehomesSettings';
import Alerts from './components/Alerts';
import Regions from './components/Regions';
import Profile from './components/Profile';

import './Settings.scss';

const SETTINGS_TABS = {
    PROFILE: 'profile',
    ALERTS: 'alerts',
    BEEHOMES_SETTINGS: 'beehomes_settings',
    TEAM: 'team',
    REGIONS: 'regions',
};

const Settings = () => {
    const members = useSelector(getCompanyUsersWithPermissions);
    const isInvitationModelOpen = useSelector(getIsInvitationModelOpen);
    const userSettings = useSelector(getUserSettings);
    const isLoggedInAsSuperAdmin = useSelector(getIsLoggedInAsSuperAdmin);
    const isLoggedInAsAdmin = useSelector(isLoggedAsAdmin);
    const dispatch = useDispatch();
    const roles = getRoles();
    const [permissions, setPermissions] = useState(null);
    const [settingTab, setSettingTab] = useState(SETTINGS_TABS.PROFILE);
    const isLimitedView = useSelector(getIsLimitedViewCompany);

    const handleSetPermission = permissions => setPermissions(!isEmpty(permissions) ? permissions : null);

    useCompareEffect(() => {
        const initialPermissions = members.reduce((acc, { id, permissions }) => ({ ...acc, [id]: permissions }), {});
        handleSetPermission(initialPermissions);
    }, [members]);

    useEffect(() => {
        dispatch(fetchUserPreferences());
        dispatch(fetchUserSettings());
    }, [dispatch]);

    useEffect(() => {
        if (settingTab === SETTINGS_TABS.TEAM) {
            dispatch(getCompany());
        }
    }, [dispatch, settingTab]);

    const togglePermission = useCallback(
        (id, permissionType) => {
            const prevValue = permissions[id][permissionType];
            const userPermissions = {
                ...permissions[id],
                [permissionType]: !prevValue,
            };
            const calculatedPermissions = {
                ...permissions,
                [id]: userPermissions,
            };
            handleSetPermission(calculatedPermissions);
            const role = calculateUserRole(userPermissions.canOperate, roles);
            dispatch(updateRole(id, role));
        },
        [dispatch, permissions, roles]
    );

    const getUserPermissions = useCallback(
        id => (permissions?.[id] ? permissions[id] : { canOperate: false }),
        [permissions]
    );

    const toggleInviteModal = useCallback(() => {
        if (isInvitationModelOpen) {
            dispatch(closeInvitationModel());
        } else {
            dispatch(openInvitationModel());
        }
    }, [isInvitationModelOpen, dispatch]);

    const handleTabChange = useCallback(tab => () => setSettingTab(tab), [setSettingTab]);

    const handleCheckSettingsCheckbox = useCallback(
        (key, upperKey = 'notifications') =>
            ({ target: { value } }) => {
                const settingsToSend = {
                    ...userSettings.data,
                    [upperKey]: {
                        ...userSettings.data[upperKey],
                        [key]: !value,
                    },
                };
                dispatch(
                    fetchUpdateUserAlertsSettings(settingsToSend, () =>
                        showToast('Preferences saved', {
                            toastType: 'toast-success',
                        })
                    )
                );
            },
        [dispatch, userSettings]
    );

    return (
        <div className="settings">
            <Menu
                handleTabChange={handleTabChange}
                SETTINGS_TABS={SETTINGS_TABS}
                settingTab={settingTab}
                showTeamTab={isLoggedInAsSuperAdmin || isLoggedInAsAdmin}
                isLimitedView={isLimitedView}
            />
            <div className="settings-content">
                {settingTab === SETTINGS_TABS.TEAM && (
                    <Team
                        getUserPermissions={getUserPermissions}
                        toggleInviteModal={toggleInviteModal}
                        togglePermission={togglePermission}
                        members={members}
                    />
                )}
                {settingTab === SETTINGS_TABS.PROFILE && (
                    <Profile handleCheckSettingsCheckbox={handleCheckSettingsCheckbox} />
                )}
                {settingTab === SETTINGS_TABS.BEEHOMES_SETTINGS && <BeehomesSettings />}
                {settingTab === SETTINGS_TABS.ALERTS && (
                    <Alerts userSettings={userSettings} handleCheckSettingsCheckbox={handleCheckSettingsCheckbox} />
                )}
                {settingTab === SETTINGS_TABS.REGIONS && <Regions />}
            </div>
            <InvitationModal isOpen={isInvitationModelOpen} onClose={toggleInviteModal} />
        </div>
    );
};

export default Settings;
